import { Star } from '@mui/icons-material';
import * as React from 'react';
import './style.css';
import {isBrowser, isMobile } from 'react-device-detect'
export default function HotelName ({name, stars}){

    let starIcons = []

    for(let i=0;i<stars;i++){
        starIcons.push(<Star className={isMobile?"star-mobile":"star"}/>)           
    }

    return(
        <span className={isMobile?"flex-column":"flex-row"} style={{fontSize:isMobile?"0.9rem":"1.3rem", justifyContent:"space-around"}}><p>{name}  </p>
        <div style={{textAlign:isMobile?"center":"left"}}> 
        {
            starIcons.map((star, index)=>{
                return <span key={index}>{star}</span>
            })
        }
        </div> 
        </span>
    )
}