import * as React from 'react';

import '../../assets/style.css';
import Footer from '../../components/footer/Footer';
import CheckBox from '../../components/formElements/CheckBox';
import { makeFormControls, validateForm } from '../../components/formElements/util';
import { isEmail, isEmpty } from '../../components/formElements/validators';
import Form from '../../components/formElements/Form';
import Table from '../../components/table/Table';
import Modal from '../../components/modal/Modal';

import axios from 'axios';
import Navbar from '../../components/nav/Navbar';

import { api } from '../../environment';
import { Hotel, Star } from '@mui/icons-material';
import HotelName from '../../components/aesthetics/HotelName';
import {isBrowser, isMobile } from 'react-device-detect'

export default function ParticipationApplication(){

    

    const [pricingOptions, setPricingOptions] = React.useState([{value:"2", text:"Srednja"}, {value:"3", text:"Kasna"}, {value:"4", text:"Posebna"}])

    const [departureDisabled, setDepartureDisabled] = React.useState(true)
    const [departureDates, setDepartureDates] = React.useState([])
    const [peopleNumber, setPeopleNumber] = React.useState(0)
    const [hotelVisible, setHotelVisible] = React.useState(false);
    const [paymentNeeded, setPaymentNeeded] = React.useState(true);


    const setNecessary = (formControls, setFormControls, bool) => {
        let formControlsCopy = structuredClone(formControls);
        for(let key in formControlsCopy){
            if(formControlsCopy[key].validity.formNecessary!=null){
                formControlsCopy[key].value = "";
                formControlsCopy[key].validity.formNecessary = bool;
                formControlsCopy[key].validity.valid= !formControlsCopy[key].validity.necessary;
                if(!bool){
                    formControlsCopy[key].validity.warning="";
                }
            }
        }
        setFormControls(formControlsCopy);
    }

    const makePricingArray = (value) =>{
        let pricings = [{value:'2', text:'Srednja'}, {value:'3', text:'Kasna'}, {value:'4', text:'Posebna'}, {value:'5', text:'Upravni/Organizacijski/Programski odbor - HPD'}];
        let prices = {
            "1" : [ 225,300,50, 0],
            "2" : [300, 375, 125, 0] 
        }
        if(value=="2"){
            pricings = [{value:'2', text:'Srednja'}, {value:'3', text:'Kasna'}, {value:'4', text:'Posebna'}];
            prices = {
                "1" : [225,300,50],
                "2" : [300, 375, 125] 
            }
        }

        return pricings.map((pricing, index)=>{
            return {value: pricing.value, text: pricing.text + ' - ' + prices[value][index].toString() + " €" }
        })

    }

    const makeDepartureDates = (value)=>{
        const departureDates = ['7.11.2024', '8.11.2024', '9.11.2024'];

        return departureDates.slice(Number(value)-6, departureDates.length).map((date, index)=>{
            return {value:date[0], text:date}
        })
    }

    const paymentProviderFormData=[
        {name:'name', type:'text', label:'naziv platitelja', necessary:true, formNecessary:paymentNeeded, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'address', type:'text', label:'adresa platitelja', necessary:true, formNecessary:paymentNeeded, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'oib', type:'text', label:'oib platitelja', necessary:true, formNecessary:paymentNeeded, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'email', type:'text', label:'e-mail adresa platitelja', necessary:true, formNecessary:paymentNeeded, validators:[isEmpty, isEmail], layoutStyle:{flex:'1 1 45%'}},
        {name:'payment_way', type:'select', label:'način plaćanja', necessary:true, formNecessary:paymentNeeded, validators:[isEmpty],
            options:[
                {value:'1', text:'Bankovna transakcija'},
                {value:'2', text:'Kreditna kartica'}
            ], layoutStyle:{flex:'1 1 100%'}}
    ]

    const [paymentProviderFormControls, setPaymentProviderFormControls] = React.useState(makeFormControls(paymentProviderFormData));

    const participantFormData=[
        {name:'first_name', type:'text', label:'ime', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'last_name', type:'text', label:'prezime', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'oib', type:'text', label:'oib', necessary:false, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'address', type:'text', label:'adresa', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'state', type:'text', label:'država', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'city', type:'text', label:'grad', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'phone', type:'text', label:'telefon', necessary:false, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'mobile', type:'text', label:'mobitel', necessary:false, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'e_mail', type:'text', label:'osobna e-mail adresa', necessary:true, validators:[isEmpty, isEmail], layoutStyle:{flex:'1 1 45%'}},
        {name:'hpk_number', type:'text', label:'broj ovlaštenja HPK', necessary:false, validators: [isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'hpd_membership', type:'select', label:'Stanje članstva HPD', defaultValue:"", necessary:true, 
            options:[{value:"1", text:"Da"}, {value:"2", text:"Ne"}], validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}, 
            callback:(e)=>{
                setPricingOptions(makePricingArray(e.target.value))
            }},
        {name:'payment_type', type:'select', label:'Vrsta kotizacije', defaultValue:"", necessary:true,
            options:pricingOptions, callback:(e)=>{
                if(e.target.value=="5") {
                    setPaymentNeeded(false);
                    setNecessary(paymentProviderFormControls, setPaymentProviderFormControls, false);
                } else {
                    setPaymentNeeded(true)
                    setNecessary(paymentProviderFormControls, setPaymentProviderFormControls, true);
                }}, layoutStyle:{flex:'1 1 45%'}, validators:[isEmpty]}
    ]

    const hotelFormData=[
        {name:'hotel', type:'select', label:'hotel', necessary:true, formNecessary:false, validators:[isEmpty],
            options:[
                {value:'3', text:'Hotel Admiral (4 zvjezdice'},
            ], layoutStyle:{flex:'1 1 100%'}},
        {name:'person_count', type:'select', label:'broj osoba u sobi', necessary:true, formNecessary:false, validators:[isEmpty],
            options:[
                {value:'1', text:'1'},
                {value:'2', text:'2'}
            ], layoutStyle:{flex:'1 1 100%'},
        callback:(e)=>{
            setPeopleNumber(Number(e.target.value))
        }}, 
        {name: 'date_arrival', type:'select', label:'datum dolaska', necessary:true, formNecessary:false, validators:[isEmpty],
            options:[
                {value:'6', text:'6.11.2024'},
                {value:'7', text:'7.11.2024'},
                {value:'8', text:'8.11.2024'}
            ], layoutStyle:{flex:'1 1 45%'},
            callback: (e)=>{
                setDepartureDates(makeDepartureDates(e.target.value));
                setDepartureDisabled(false);
            }
        },
        {name: 'date_departure', type:'select', label:'datum odlaska', necessary:true, formNecessary:false, validators:[isEmpty],
            options:departureDates, layoutStyle:{flex:'1 1 45%'},
            disabled:departureDisabled,
        },
        {name:'name_roommate', type:'text', label:'ime i prezime druge osobe u sobi', necessary:peopleNumber==2, validators:[isEmpty], formNecessary:false, layoutStyle:{flex:'1 2 45%'}, hidden:peopleNumber<2},
        {name:'note', type:'textarea', label:'napomena', necessary:false, formNecessary:false, layoutStyle:{flex:'1 1 100%'}}
    ]

    const [participantFormControls, setParticipantFormControls] = React.useState(makeFormControls(participantFormData));
    const [hotelFormControls, setHotelFormControls] = React.useState(makeFormControls(hotelFormData));
    

    const pricingTableHeader = [
        {name:"order", label:"#"},
        {name:"description", label:"opis"},
        {name:"price", label:"cijena"},
    ]

    const makePaymentDescription = (isMember, paymentType) => {
        if(paymentType=="5"&&isMember=="1"){
            return "Kotizacija za članove Upravnog/Organizacijskog/Programskog odbora"
        }
        const pricings = ['Rana', 'Srednja', 'Kasna', 'Posebna'];
        return isMember!=""&&paymentType!=""&&paymentType!="5"?`${pricings[Number(paymentType)-1]} kotizacija ${isMember=="1"?"za članove HPD-a":""}`:"";
    }

    const makePaymentPrice = (isMember, paymentType)=>{
        const prices = {
            "1" : [150, 225,300,50, 0],
            "2" : [225, 300, 375, 125] 
        }
        return isMember!=""&&paymentType!=""&&paymentType!="5"?prices[isMember][Number(paymentType)-1]:0
    }

    const makeHotelPriceDescription = () => {
        let number_nights = Number(hotelFormControls['date_departure'].value)-Number(hotelFormControls['date_arrival'].value)
        let show = hotelFormControls['person_count'].value != "" && number_nights != 0;
        
        if(participantFormControls['payment_type'].value=="5"){
            return show?"Cijena boravka za članove Upravnog/Organizacijskog/Programskog odbora":""
        }
       
        return show?`Cijena boravka (${hotelFormControls['person_count'].value=="1"?"jedna osoba":"dvije osobe"}) za ${number_nights==1?"jedno noćenje":number_nights==2?"dva noćenja":'tri noćenja'} na bazi ${hotelFormControls['hotel'].value=="1"?"pansiona s doručkom i ručkom.":"noćenja s doručkom."}`:""
    }

    const computeHotelpricing = (peopleNumber, hotel, table=false) => {
        const prices = {
            "" : [],
            "1": [162, 100, 100, 106, 95],
            "2": [230, 126, 126, 132, 116]
        }
        if(table){
            return prices[peopleNumber][Number(hotel)-1]
        }
        if(peopleNumber!=""&&hotel!=""){
            if(participantFormControls['payment_type'].value=="5"){
                return ""
            }
            return prices[peopleNumber][Number(hotel)-1]
        } else return ""
        
    }

    const sumPricing = () => {
        return (Number(hotelFormControls['date_departure'].value)-Number(hotelFormControls['date_arrival'].value))*computeHotelpricing(hotelFormControls['person_count'].value, hotelFormControls['hotel'].value)+makePaymentPrice(participantFormControls['hpd_membership'].value, participantFormControls['payment_type'].value);
    }

    const pricingTableRows = [
        {order: "1.", description:makePaymentDescription(participantFormControls['hpd_membership'].value,participantFormControls['payment_type'].value), 
        price:makePaymentPrice(participantFormControls['hpd_membership'].value,participantFormControls['payment_type'].value).toString()+'.00 €'},
        {order: "2.", description:makeHotelPriceDescription(), price:`${computeHotelpricing(hotelFormControls['person_count'].value, hotelFormControls['hotel'].value)*(hotelFormControls['date_departure'].value!=""?Number(hotelFormControls['date_departure'].value)-Number(hotelFormControls['date_arrival'].value):0)}.00 €`},
        {order: "", description:"", price:`total: ${sumPricing().toString()}.00 €`}
    ]

    const paymentPricingHeader = [
        {name:"payment_type", label:"", width:"25%"},
        {name:"hpd_members", label:"Članovi HPD-a*", width:"45%"},
        {name:"rest", label:"Ostali", width:"20%"},
    ]

    

    const paymentPricingBody = [
        {payment_type:"Rana (do 31.07.2024.)", hpd_members:"150.00 €", rest:"225.00 €"},
        {payment_type:"Srednja (od 1.8.2024. do 18.10.2024.)", hpd_members:"225.00 €", rest:"300.00 €"},
        {payment_type:"Kasna (od 19.10.2024. do 5.11.2024)", hpd_members:"300.00 €", rest:"375.00 €"},
        {payment_type:"Posebna**", hpd_members:"50.00 €", rest:"125.00 €"}
    ]

    const hotelPricingHeader = [
        {name:"hotelName", label:"Hotel", width:"40%"},
        {name:"priceSingle", label:"Jedna osoba*", width:"20%"},
        {name:"priceDouble", label:"Dvije osobe*", width:"20%"},
        {name:"service", label:"Uključeno", width:"20%"},
    ]

    const hotelPricingBody=[
        {hotelName:<HotelName name='Hotel Admiral'  stars={4}/>, priceSingle:computeHotelpricing('1','3', true)+ " €", priceDouble:computeHotelpricing('2','3', true)+ " €", service:'Noćenje s doručkom'},
    ]

    const [modalHidden, setModalHidden] = React.useState(true);

    const sendFormData = () =>{
        let formData = {'participant':participantFormControls,
                        'hotel': (hotelVisible)?hotelFormControls:null,
                        'payment':(paymentNeeded)?paymentProviderFormControls:null}
        axios.post(api+'/submit_participation', formData).then(
            (response) => {
                console.log(response)
            }
        ).catch((err)=>{console.log(err)});
    }

    const submitForm = () =>{

        let participantFormValid = validateForm(participantFormData, participantFormControls, setParticipantFormControls);
        let hotelFormValid = hotelVisible?validateForm(hotelFormData, hotelFormControls, setHotelFormControls):true;
        let paymentProviderFormValid = validateForm(paymentProviderFormData, paymentProviderFormControls, setPaymentProviderFormControls);
        if (participantFormValid && hotelFormValid && paymentProviderFormValid){
            sendFormData();
            setModalHidden(false);
        } else {
        }

    }

    return (
        <>
        <Navbar/>

        <div className="flex-column" style={{paddingTop:"18vh",gap:"4rem", width:"100%", justifyContent:"center"}}>
            <div style={{width:isMobile?"96vw":"50vw", margin:"auto"}}>
            <div><h1 className="font-gradient-large" style={{fontWeight:"400", fontSize:isMobile?"2rem":"3.5rem", textAlign:"center"}}>Prijava sudjelovanja</h1></div>
                <div>
                    <h2 className="form-subtitle">Podatci o prijavitelju</h2>
                    <div className={isMobile?"flex-column":"flex-row"} style={{flexWrap:"wrap", gap:"1.2rem"}}>
                        <Form formData={participantFormData} formControls={participantFormControls} setFormControls={setParticipantFormControls}/>
                    </div>
                </div>
                <div>
                <div style={{padding:"1.7rem", paddingBottom:"0rem"}}>
        <CheckBox fontSize="1.5rem" fontWeight='700' label="Želim prijaviti hotelski smještaj" onChange={()=>{
            setHotelVisible(!hotelVisible)
            setNecessary(hotelFormControls, setHotelFormControls, !hotelVisible)
        }}/>
        </div>
        <div hidden={!hotelVisible}>
        <h2 className="form-subtitle" >Podatci o smještaju</h2>
        <div className={isMobile?"flex-column":"flex-row"} style={{flexWrap:"wrap", gap:"1.2rem"}}>
            <Form formData={hotelFormData} formControls={hotelFormControls} setFormControls={setHotelFormControls}/>
            <h3 className="form-subtitle">Cijene smještaja</h3>
            <Table header={hotelPricingHeader} dataSource={hotelPricingBody}/>
            <div style={{color:"rgb(34, 61, 105)"}}>
            <p>*istaknuta je cijena po noćenju</p>
            </div>
        </div>
        </div>
                </div>
                <div hidden={!paymentNeeded}>
                    <h2 className="form-subtitle">Podatci o platitelju</h2>
                    <div className={isMobile?"flex-column":"flex-row"} style={{flexWrap:"wrap", gap:"1.2rem"}}>
            <Form formData={paymentProviderFormData} formControls={paymentProviderFormControls} setFormControls={setPaymentProviderFormControls}/>
        </div>
                </div>
                <div className="tool-tray"><button onClick={submitForm} className='pillbox'>Prijava</button></div>
                <div className="table-wrap">
                    <h2 className="form-subtitle">Izračun cijene sudjelovanja</h2>
                    <Table header={pricingTableHeader} dataSource={pricingTableRows}/>
                </div>
                <div className="table-wrap">
                    <h2 className='form-subtitle' style={{paddingTop:"1rem"}}>Iznosi kotizacije (s uključenim PDV-om)</h2>
                    <Table header={paymentPricingHeader} dataSource={paymentPricingBody}/>
                    <div style={{color:"rgb(34, 61, 105)", textAlign:"center", paddingTop:"1rem"}}>
                        <p>* odnosi se na članove Hrvatskog psihološkog društva s podmirenom članarinom za 2024.godinu</p>
                        <p>** umirovljeni psiholozi, nezaposleni psiholozi, studenti psihologije preddiplomskog i diplomskog studija i psiholozi - vježbenici</p>
                        <p style={{color:"red",paddingTop:"1rem"}}>Za bilo kakva pitanja oko kotizacija, slanja predračuna i/ili računa (e-računa), molimo Vas kontaktirajte nas putem e-maila goran.grbic@vivid-original.com ili na mobitel +385(0)98 170 6028</p>
                        </div>
                </div>
                
            </div>
            <Modal hidden={modalHidden} title="Vaša prijava je zaprimljena" content={<div>Na vašu e-mail adresu primit ćete potvrdu o zaprimanju vaše prijave s primjerkom vašeg odgovora. Predračun za plaćanje kotizacije dobit ćete od Kongresne agencije u roku od 48 sati.<br/><br/>
            Za sva dodatna pitanja budite slobodni obratiti se Kongresnoj agenciji Vivid Original, kontakt osoba Goran Grbić (goran.grbic@vivid-original.com).</div>} 
            buttons={[<button className="modal-button" onClick={()=>{
                setModalHidden(true);
                window.location.reload();
            }}>U redu</button>]}/>
            <div className="background-overlay">
                <Footer/>
            </div>
        </div>
        </>
    )
}