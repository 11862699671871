import * as React from 'react';

import './style.css';
import CounterElement from './CounterElement';

export default function Counter({goalDate}){
    const monthLengths = [31,29,31,30,31,30,31,31,30,31,30,31]

    const [startDate, setStartDate] = React.useState(new Date());

    const calcMonths = (startDate, goalDate) =>{
        return startDate.getDate()<=goalDate.getDate()?goalDate.getMonth()-startDate.getMonth():goalDate.getMonth()-startDate.getMonth()-1
    }

    const calcDays = (startDate, goalDate) =>{
        return startDate.getDate()<=goalDate.getDate()?goalDate.getDate()-startDate.getDate():goalDate.getDate() + monthLengths[startDate.getMonth()] - startDate.getDate()
    }

    const calcSeconds = () => {
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate()+1);
        tomorrow.setHours(goalDate.getHours(), goalDate.getMinutes(), goalDate.getSeconds(), 0)
        return tomorrow.getTime()- startDate.getTime();
    }

    const [months, setMonths] = React.useState(calcMonths(startDate, goalDate)); 
    const [days, setDays] = React.useState(calcDays(startDate, goalDate))
    
    const calcHours = () => {
        return Math.floor((calcSeconds()/1000/60/60))
    }

    const calcMinutes = ()=>{
        return Math.floor((calcSeconds()/1000/60)%60)
    }

    const calcSecondsMod = () =>{
        return Math.floor((calcSeconds()/1000)%60);
    }

    const [hours, setHours] = React.useState(calcHours());
    const [minutes, setMinutes] = React.useState(calcMinutes());
    const [seconds, setSeconds] = React.useState(calcSecondsMod());

    const refreshBig = () =>{
        setMonths(startDate.getDate()<=goalDate.getDate()?goalDate.getMonth()-startDate.getMonth():goalDate.getMonth()-startDate.getMonth()-1)
        setDays(startDate.getDate()<goalDate.getDate()?goalDate.getDate()-startDate.getDate():goalDate.getDate() + monthLengths[startDate.getMonth()] - startDate.getDate())
    }

    const setTime = () => {
        setStartDate(new Date());
        setHours(startDate.getHours()>=goalDate.getHours()?calcHours():(calcHours()-24));
        setMinutes(calcMinutes());
        setSeconds(calcSecondsMod())
    }

    React.useEffect(()=>{
        let now = new Date();
        if(now.getMonth()!=startDate.getMonth() || now.getDate()!=startDate.getDate() || now.getHours()!=startDate.getHours()){
            
            refreshBig();
        }
        const timer = setInterval(()=>{
            setTime()
        }, 1000);
        return () => clearInterval(timer);

    })


    return (
        <>
        {
            goalDate!=undefined?
            <div className="flex-row" style={{justifyContent:"space-between"}}>
                <CounterElement label="mjeseca" value={months}/>
                <CounterElement label="dana" value={days}/>
                <CounterElement label="sati" value={hours}/>
                <CounterElement label="minuta" value={minutes}/>
                <CounterElement label="sekunda" value={seconds}/>
            </div>:<></>
        }
        </>
    )
}